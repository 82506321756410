.cartas {
  background-color: #e9e9e9;
  height: 250px;
  width: 200px;
  margin: 20px;
  display: inline-block;
}

/* &/////////////////////////////// GENERAL /////////////////////////////// */
.espacioNav {
  padding-top: 92px;
}

.divButon {
  background-color: rgb(0, 0, 0);
  padding: 15px 30px 15px 30px;
  color: white;
  display: initial;
  font-weight: 400;
  border-style: solid;
  /* border-style: solid; */
}
.divButon:hover {
  background-color: rgb(255, 255, 255);
  color: black;
  border-style: solid;
  font-weight: 400;
  cursor: pointer;
}
.simInput{
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;  
}
/*///////////////////////////////// NAVBAR /////////////////////////////////*/

.brand {
  font-weight: bold;
  font-size: 20px;
}

.site-header {
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 99;
}
.site-header a {
  color: black;
}

.site-header__wrapper {
  margin: 10px 0 10px 0;
  padding-left: 100px;
  padding-right: 100px;
}
@media (min-width: 600px) {
  .site-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 600px) {
  .nav__wrapper {
    display: flex;
  }
}

@media (max-width: 599px) {
  .nav__wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #d9f0f7;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }
  .nav__wrapper.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.nav__item {
  display: block;
  padding: 1.5rem 1rem;
}

.nav__toggle {
  display: none;
}
@media (max-width: 599px) {
  .nav__toggle {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

.desplegable {
  position: absolute;

  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  z-index: 1;
  background-color: rgb(0, 0, 0);
  right: 110px;

  color: white;
  display: initial;
  font-weight: 400;
  border-style: solid;
  border-color: black;
}
.desplegable p {
  padding: 10px 12px;
}
.desplegable p:hover {
  background-color: white;
  color: black;
  padding: 10px 12px;
  cursor: pointer;
  font-weight: 400;
}

/* /////////////////////////////   Hompage ///////////////////////////////////// */

.portada {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  height: 500px;
  padding: 30px;
}

.flex-item:nth-child(1) {
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  min-width: 800px;
}

.flex-item:nth-child(2) {
  order: 0;
  flex: 1 1 auto;
  align-self: center;
  width: 800px;
  align-self: flex-end;
}
.flex-item p {
  font-size: 70px;
  color: black;
  font-weight: 700;
}
.flex-item a {
  font-size: 70px;
  color: black;
  font-weight: 700;
  background-color: #0d8d43;
  color: #ffffff;
  padding: 10px;
}

.popUp {
  height: 100%;
  width: 100%;
  background: #4949491f;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login {
  position: relative;
  box-shadow: 0px 1px 8px 2px #999;
  background: white;
  border-radius: 10px;
  padding: 20px;
}
.registration{
  text-align: center;
  margin: auto;
}
.closeForm {
  border-radius: 20px;
  background: white;
  border-color: black;
  box-shadow: 0px 1px 8px 2px #999;
  font-size: 15px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
  font-weight: bold;
}
.closeForm:hover{
  background: black;
  color: white;
}
.typeDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.typeButton{
  padding: 20px;
  width: 40%;
  border: 1.5px solid black;
  border-radius: 10px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}
.typeButton:hover{
  background: black;
  color: white;
}
.typeButton.active{
  background: black;
  color: white;
}
.company-info{
  margin-top: 40px;
  padding-top: 15px;
}
.company-info-text {
	margin:0 0 1em;
	color:rgb(0, 0, 0);
	font-size:1.5em;
	text-align:center;
  display:flex;
  align-items:center;
}
.company-info-text:before,.company-info-text:after {
	content:" ";
  flex:1 0 0%;
	height:2px;
	background:rgba(0,0,0,0.8);
  margin:0 1.5rem 0 0;
}
.company-info-text:after {
  margin:0 0 0 1.5rem;
}
.input50{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input50 > * {
  width: 40%;
}
.company-info > * > * {
  margin: 10px 0;
}
.company-info > * {
  margin: 10px 0;
}

/* ///////////////////////////// PERFIL /////////////////////////////// */

.selectores {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.selectores div {
  min-width: 100px;
  text-align: center;
  background-color: black;
  color: white;
}
.selectores div:hover {
  min-width: 100px;
  text-align: center;
  background-color: white;
  color: black;
}

/*----------LOADER----------*/

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(0, 0, 0);
  border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*----------------------------------*/